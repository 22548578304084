import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location}>
        <SEO
          title="Главная"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <div className="content">
          <h1>
            Привет!{" "}
            <span role="img" aria-label="wave emoji">
              👋
            </span>
          </h1>
          <p>
            Я управляю Front-End разработкой и участвую в создании новых
            продуктов.
          </p>
          <p>
            Помогаю разработчикам и тимлидам решать задачи и прокачиваться на{" "}
            <a href="https://solvery.io/mentor/bzvyagintsev" target="_blank" rel="noreferrer">
              solvery.io
            </a> и{" "}<a href="https://htmlacademy.ru/" target="_blank" rel="noreferrer">
              HTML Academy
            </a>.
            {/* <p>
              и{" "}<a href="https://hexlet.io" target="_blank" rel="noreferrer">
                Hexlet
              </a>
            </p> */}
          </p>
          {/* <p>
            Я работаю в компании{" "}
            <a href="https://pik.digital" target="_blank" rel="noreferrer">
              ПИК Digital</a>, которая делает стройку современной и технологичной.
              Управляю Front-End разработкой и участвую в создании новых продуктов.
          </p> */}
          <p>
            Делюсь своим опытом в <Link to="/blog">блоге</Link>, а ещё публикую статьи на&nbsp;
            <a
              href="https://habr.com/ru/users/bzvyagintsev/posts/"
              target="_blank"
              rel="noreferrer"
            >
              Хабре
            </a>{" "}
            и на{" "}
            <a
              href="https://dev.to/bzvyagintsev"
              target="_blank"
              rel="noreferrer"
            >
              dev.to
            </a>
            .
          </p>
        </div>
        <h2>
          Новое в блоге
        </h2>
        {/* <h2>
          Новое в{" "}
          <Link to="/blog" style={{ boxShadow: `none` }}>
            блоге
          </Link>
        </h2> */}
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug} style={{ marginBottom: rhythm(1 / 2) }}>
              <Link to={`blog${node.fields.slug}`}>{title}</Link>
            </div>
          )
        })}
        {/* <div>
          <Link to="/blog">Все посты →</Link>
        </div> */}
      </Layout>
    )
  }
}

export default IndexPage

export const newPosts = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMM YYYY", locale: "ru-RU")
            title
            description
          }
        }
      }
    }
  }
`
